:root {
  --text-color: black;
  --second-text-color: white;

  --grey-color: #f6f7f9;
  --grey-color-second: #00000050;
  --grey-color-third: rgb(151, 151, 151);

  --background-page-color: #f5f5f5;
  --background-component-color: #ffffff;

  --accent-color: #0b99ff;
  --accent-color-second: #0b99ff50;

  --inactive-button-color: #e9f4ff;

  --active-positive-color: #36b14a;
  --inactive-positive-color: #36b14a30;

  --active-negative-color: #f34343;
  --inactive-negative-color: #f3434330;

  --border-radius-small: 10px;
  --border-radius-medium: 15px;
  --border-radius-big: 20px;

  --border: 1px solid var(--accent-color-second);
  --border-grey: 2px solid var(--grey-color);
  --border-grey-second: 1px solid var(--grey-color-second);
  --border-white: 1px solid white;
  --border-alert: 3px solid var(--active-negative-color);
  --border-positive: 3px solid var(--accent-color);

  --box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

  --padding-desktop: 20px;
  --padding-mobile: 15px;

  --gap-big: 30px;
  --gap-medium: 20px;
  --gap-small: 15px;

  @media (max-width: 992px) {
    --gap-big: 20px !important;
    --gap-medium: 15px !important;
    --gap-small: 10px !important;

    --padding-desktop: 15px !important;
    --padding-mobile: 10px !important;
  }
}
