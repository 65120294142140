.block {
  border-radius: var(--border-radius-small);
  height: 20px;
  color: black;
  display: block;
}

.skeleton_max-width {
  width: 100% !important;
}

/* Table */
.skeleton-table__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--gap-big);
}
.skeleton-table-item {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-small);
}
.skeleton-table__left {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}
.skeleton-table__description {
  display: flex;
  gap: var(--gap-small);
}
.skeleton-table__buttons .block {
  height: 35px !important;
  width: 100px !important;
}

.skeleton-table-item .block {
  width: 400px;
}

.skeleton-table__description .block {
  width: 100px !important;
}

@media (max-width: 992px) {
  .skeleton-table__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .skeleton-table-item {
    flex-direction: column;
  }
  .skeleton-table__description {
    flex-direction: column;
  }
  .skeleton-table-item .block {
    width: 100%;
  }
  .skeleton-table__buttons .block {
    width: 100% !important;
  }
}

.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Improver */

.skeleton-improver {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    gap: var(--gap-small);
}
.skeleton-improver__header {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}
.skeleton-improver__description {
    display: flex;
    gap: var(--gap-small);
}
.skeleton-improver__services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--gap-small);
}
.skeleton-improver__payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-small);
}

.skeleton-improver .block {
    width: 200px;
    height: 20px;
}

.skeleton-improver__header .block {
    width: 400px;
}

.skeleton-improver__description .block {
    width: 100px;
}
.skeleton-improver__services .block {
    width: 100%;
    max-width: 250px;
    height: auto;
    aspect-ratio: 1 / 1 ;
}
.skeleton-improver__payment .block:first-child{
    width: 250px;
    height: 25px;
}
.skeleton-improver__payment .block{
    height: 40px;
}

@media (max-width: 636px) {
  .skeleton-improver__header .block {
    width: 100%;
}
  .skeleton-improver__services {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 460px) {
  .skeleton-improver__services {
    grid-template-columns: repeat(2, 1fr);
  }
}