.start {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-medium) !important;
}

.start-info {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  justify-content: space-between;
}

.start .img {
  width: 100%;
  height: 350px;
  background-color: rgb(236, 236, 236);
  border-radius: var(--border-radius-small);
}

.start button {
  width: max-content;
}

.start a {
  display: flex;
  justify-content: start;
}

.start svg {
  width: auto;
  height: 100%;
}

.start-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 300px;
    border-radius: var(--border-radius-small);
  
}

@media (max-width: 768px) {
  .start .landing-title h3,
  .start .landing-title h2 {
    text-align: center;
  }
  .start {
    grid-template-columns: repeat(1, 1fr);
  }
  .start .start-photo {
    display: none;
  }

  .start a {
    display: flex;
    justify-content: center;
  }
}
