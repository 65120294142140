.pagination {
  display: flex;
  gap: var(--gap-small);
  align-items: center;
  justify-content: center;
}

.pagination-item {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-color);
  padding: var(--padding-mobile);
  border-radius: var(--border-radius-small);
  cursor: pointer;
}

.pagination-item svg {
  width: 7px;
}

.pagination-item:hover {
  transform: scale(0.93);
}

.pagination-item_active {
  background-color: var(--accent-color);
  color: white;
}
