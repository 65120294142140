.intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "intro-title intro-img"
    "intro-text intro-img"
    "intro-buttons intro-img";
  gap: var(--gap-big) !important;
  padding: calc(var(--padding-desktop) * 3) var(--padding-desktop) !important;
}

/* .intro-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--gap-small);
} */

.intro p {
  grid-area: intro-text;
  font-size: 20px;
}

.intro img {
  grid-area: intro-img;
  width: 100%;
}

.intro a {
  white-space: nowrap;
}

.intro h1 {
  grid-area: intro-title;
  font-size: 45px;
}

.intro-links {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.intro-links p {
  color: var(--grey-color-second);
}

.intro-buttons {
  grid-area: intro-buttons;
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: var(--gap-big);
}

@media (max-width: 1180px) {
  .intro h1 {
    font-size: 40px;
  }

  .intro p {
    grid-area: intro-text;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .intro h1 {
    font-size: 30px;
  }
  .intro {
    padding: var(--padding-desktop) !important;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "intro-title"
      "intro-text"
      "intro-img"
      "intro-buttons";
  }
}

@media (max-width: 800px) {
  .intro-buttons {
    flex-direction: column;
  }
}
