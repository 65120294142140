.reviews {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-medium);
}

.reviews .landing-title {
  text-align: center;
}

.reviews-list {
  display: flex;
  gap: var(--gap-small);
}

.reviews-list__item {
  min-height: 350px;
  min-width: 350px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  justify-content: space-between;
  padding: var(--padding-mobile);
  background-color: var(--grey-color);
  border-radius: var(--border-radius-small);
}

.review-text {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  font-style: italic;
}

.review-person {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--gap-small);
  align-items: center;
  padding-top: var(--padding-mobile);
  border-top: var(--border-grey-second);
}

.review-person .img {
  height: 80px;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  overflow: hidden;
}

.review-person .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-person__text {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-small);
}

.review-person__text_small {
  font-size: 14px;
  color: var(--grey-color-second);
}

.reviews-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-medium);
}

.reviews-pagination__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  cursor: pointer;
}

.reviews-pagination__back {
  background-color: var(--accent-color);
  opacity: 0.3;
}

.reviews-pagination__next {
  background-color: var(--accent-color);
}

@media (max-width: 1180px) {
  .reviews-list {
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
    overflow-x: auto;
  }

  .reviews-list__item {
    /* min-width: 300px !important; */
  }

  .review-person .img {
    height: 60px;
  }
}
