.account-token {
  position: relative;
  min-width: 250px;
}

.account-token__container {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}

.account-token__info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: var(--padding-mobile);
  padding-right: calc(var(--padding-mobile) + 20px);
  background-color: var(--background-component-color);
  font-size: 14px;
}

.account-token__info_icon {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .account-token {
    width: 100%;
  }
}

.account-token__form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.account-token__edit {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--gap-small);
}
