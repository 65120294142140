.alert {
  position: fixed;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  width: fit-content !important;
  border: var(--border-alert);
  z-index: 15;
  transition:
  top .5s,
  border .8s;
}

.alert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-small);
}

.alert-content__buttons {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-small);
}

.alert-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.alert-active {
  top: var(--gap-big);
}

.alert-positive {
    border: var(--border-positive) !important;
}