.account {
  display: grid;
  gap: var(--gap-big);
}

.account .edit {
  grid-area: edit;
  margin-top: var(--gap-big);
}

.edit-form {
  display: grid;
  gap: var(--gap-small);
}

.edit-labels {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-small);
}
@media (max-width: 1240px) {
  .edit-labels {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 992px) {
  .edit-labels {
    grid-template-columns: repeat(1, 1fr);
  }
}

.edit-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap-small);
}
@media (max-width: 992px) {
  .edit-buttons {
    grid-template-columns: repeat(1, 1fr);
  }
}

.account-up {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'account-info token' 'edit edit';
  column-gap: var(--gap-big);
}

@media (max-width: 1380px) {
  .account-up {
    grid-template-areas: 'account-info' 'edit' 'token';
  }

  .account-up .account-token {
    margin-top: var(--gap-big);
  }
}

.account-personal {
  grid-area: account-info;
  width: 100%;
}

.account-personal__content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--gap-big);
  justify-content: space-between;
}

@media (max-width: 1240px) {
  .account-personal__content {
    grid-template-columns: repeat(1, 1fr);
  }
}

.account-content__left {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap-big);

}

.account-content__left img {
  border-radius: 100%;
  max-width: 100px;
  aspect-ratio: 1 / 1;
}

.account-content__text {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-small);
}
@media (max-width: 1240px) {
  .account-content__text {
    flex-direction: row;
    justify-content: space-between;
  }
  .account-content__buttons {
    flex-direction: column;
  }
  .account-content__left img {
    width: 50px;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 550px) {
  .account-content__buttons {
    flex-direction: row;
  }
  .account-content__text {
    flex-direction: column;
  }
}

.account-info {
  display: grid;
  gap: var(--gap-small);

}

.account-email {
  font-size: 24px;
}

@media (max-width: 992px) {
  .account-email {
    font-size: 20px;
  }
}

.account-phone {
  font-size: 20px;
}
@media (max-width: 992px) {
  .account-phone {
    font-size: 16px;
  }
}
.account-content__buttons {
  display: flex;
  gap: var(--gap-small);
}

.account-bonus {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--gap-big);
}
@media (max-width: 992px) {
  .account-bonus {
    width: 100%;
  }
}

.account-links {
  display: flex;
  flex-direction: column;
}

.account-links__item {
  display: flex;
  gap: var(--gap-small);
  align-items: center;
  padding: var(--padding-desktop);
  border-radius: var(--border-radius-small);
  color: var(--text-color);
  background-color: var(--inactive-button-color);
}

.account-links__item path {
  fill: var(--accent-color);
}

.payment-history {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}

.payment-history__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--gap-small);
}

.payment-list__item {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: var(--gap-small);
  align-items: center;

  background-color: var(--grey-color);
  padding: var(--gap-small);
  border-radius: var(--border-radius-small);
}

.payment-list__item:hover {
  transform: scale(0.99);
}

.payment-item__info {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.payment-item-info__service {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  background-color: var(--accent-color-second);
  border-radius: var(--border-radius-small);
}

.payment-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color);
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.payment-circle path {
  fill: white;
}

.payment-item-info__text {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.payment-item-info__text h3 {
  font-weight: 500;
}

.payment-item-info__text p {
  color: var(--grey-color-second);
}

.payment-item-info__date {
  text-align: center;
  opacity: .5;
}

.payment-item__more {
  display: flex;
  justify-self: flex-end;
  align-items: center;
  gap: var(--gap-small);

  color: var(--active-negative-color);
}


@media (max-width: 1439px) {
  .payment-list__item {
    gap: var(--gap-small);
  }
  .payment-item__more {
    flex-direction: column;
    justify-self: normal;
  }
  .payment-more__price_desktop {
    display: none;
  }
  .payment-more__price_mobile {
    display: block;
  }
}

@media (max-width: 992px) {
  .payment-history__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .payment-list__item {
    grid-template-columns: 1fr;
    gap: var(--gap-small);
  }
  .payment-item-info__date {
    text-align:start;
  }
}

@media (max-width: 631px) {
  .payment-history__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.payment-more__price_positive {
  width: 100%;
  color: var(--active-positive-color);
  font-weight: 500;
}

.payment-more__price_negative {
  width: 100%;
  color: var(--active-negative-color);
  font-weight: 500;
}

