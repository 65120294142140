.advantages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-small);
}

.advantages .landing-title {
    gap: calc(var(--gap-small) * 2);
}

.advantages-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gap-big);
}

.advantages-list__item {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
    align-items: center;
    color: var(--accent-color);
}

.advantages-item__title {
    font-size: 52px;
    font-weight: 600;
}

.advantages-item__text {
    opacity: .5;
}

@media (max-width: 768px) {
    .advantages {
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
    }
    .advantages-item__title {
        font-size: 40px;
    }
    .advantages .landing-title {
        gap: var(--gap-small);
    }
}