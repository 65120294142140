.login .component {
  display: grid;
  margin: 0 auto;
  margin-top: 5%;
  max-width: 500px;
  width: 500px;
  height: fit-content;
  text-align: center;
}

.login header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login-form {
  text-align: left;
}

.login-links {
  display: flex;
  justify-content: space-between;
}

.login-input__double {
  display: grid;
  gap: var(--gap-small);
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 992px) {
  .login-input__double {
    grid-template-columns: repeat(1, 1fr);
  }
  .login .component {
    width: 100% !important;
  }
}

.login-privacy {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.privacy span {
  display: block;
}
