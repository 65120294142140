@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope/Manrope-VariableFont_wght.ttf") format(truetype);
}

* {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

.loader {
  display: flex;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid var(--accent-color);
  border-bottom-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  display: flex;
  /* align-items: center; */
  left: 0;
  top: 0;
  z-index: 10;
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: var(--padding-mobile);
}

.spiner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid var(--accent-color);
  border-bottom-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

html {
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

body {
  margin-bottom: 70px;
  background-color: var(--background-page-color);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--background-page-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--accent-color-second);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--accent-color);
}

input:not([type="range"]) {
  width: 100%;
  height: 40px;
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 var(--padding-mobile);
}
@media (max-width: 992px) {
  input:not([type="checkbox"]) {
    height: 35px !important;
  }
}

/* Custom input range */
.range {
  width: 100%;
  display: grid;
}

.range__content {
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
}

.range__slider {
  width: 100%;
  height: 5px;
  background-color: var(--grey-color-second);
  border-radius: 4rem;
  overflow: hidden;
}

.range__slider-line {
  width: 100%;
  height: 100%;
  background: var(--active-positive-color);
  width: 0;
}

.range__thumb {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
}

.range__input {
  appearance: none;
  width: 100%;
  height: 5px;
  position: absolute;
  opacity: 0;
}

.range__input::-webkit-slider-thumb {
  appearance: none;
  width: 32px;
  height: 32px;
}

.range__input::-webkit-slider-thumb:hover {
  cursor: pointer;
}

.arrow {
  position: absolute;
  transform: translate(-50%, -5px);
}

/* End custom input range */

.hidden {
  display: none !important;
}

/* chexbox */

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: var(--accent-color);
  --tick: #fff;
  position: relative;
}
.checkbox input,
.checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}
.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox input:hover {
  --s: 2px;
  --b: var(--border-hover);
}
.checkbox input:checked {
  --b: var(--border-active);
}
.checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--border-active));
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}
.checkbox.path input:checked {
  --s: 2px;
  transition-delay: 0.4s;
}
.checkbox.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}
.checkbox.path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}
.checkbox.bounce {
  --stroke: var(--tick);
}
.checkbox.bounce input:checked {
  --s: 11px;
}
.checkbox.bounce input:checked + svg {
  -webkit-animation: bounce 0.4s linear forwards 0.2s;
  animation: bounce 0.4s linear forwards 0.2s;
}
.checkbox.bounce svg {
  --scale: 0;
}

@-webkit-keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* end chexbox */

select {
  outline: none;
  border: none;
  color: var(--text-color);

  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../images/arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0 30px 0 var(--padding-mobile);
  min-height: 22px;
}

.select {
  border: none;
  padding: 0 30px 0 var(--padding-mobile);
  height: 40px;
  border-radius: var(--border-radius-small);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../images/arrow.svg"),
    linear-gradient(to bottom, #e9f4ff 0%, #e9f4ff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-width_full {
  width: 100%;
}

@media (max-width: 992px) {
  .select {
    height: 35px !important;
  }
}

select * {
  outline: none;
  background-color: #e9f4ff;
  color: var(--text-color);
}

label {
  width: 100%;
  display: grid;
  gap: 10px;
}

.span-row {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.span-row_nogap {
  width: 100%;
  display: flex;
  align-items: center;
}

button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: var(--border-radius-small);
  padding: 0 20px;
  cursor: pointer;
}
@media (max-width: 992px) {
  button {
    height: 35px;
  }
}

.button-default:hover {
  svg > path {
    fill: white;
  }
}

.button-red:hover {
  svg > path {
    stroke: white;
  }
}

button:active {
  transform: scale(0.97);
}

.button-wide {
  min-width: 135px;
}

.button-width_auto {
  width: auto;
  width: max-content;
}

.button-width_full {
  width: 100%;
}

.button-default {
  background-color: var(--inactive-button-color);
  color: var(--text-color);
  transition: 0.2s ease-in-out background-color, color;
}

.button-default:hover {
  background-color: var(--accent-color);
  color: var(--second-text-color);
}

.button-red {
  background-color: var(--inactive-negative-color);
  color: var(--text-color);
  transition: 0.2s ease-in-out background-color, color;
}

.button-red:hover {
  background-color: var(--active-negative-color);
  color: var(--second-text-color);
}

.button-positive {
  color: var(--text-color);
  background-color: var(--inactive-positive-color);
  transition: 0.2s ease-in-out background-color, color;
}

.button-positive:hover {
  background-color: var(--active-positive-color);
  color: var(--second-text-color);
}

.button-square {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.button-active {
  background-color: var(--accent-color);
  color: var(--second-text-color);
}

.buttons-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  white-space: nowrap;
  gap: var(--gap-small);
}

@media (max-width: 500px) {
  .buttons-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

a {
  color: #0b99ff;
  text-decoration: none;
}

h1,
h2 {
  font-size: 24px;
  font-weight: 500 !important;
}

@media (max-width: 992px) {
  h1,
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px !important;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500 !important;
}

svg {
  width: 20px;
  height: 20px;
}

form {
  display: grid;
  gap: var(--gap-small);
}

.component {
  display: grid;
  gap: var(--gap-small);
  background-color: var(--background-component-color);
  padding: var(--padding-desktop);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow);
}

@media (max-width: 992px) {
  .component {
    width: 100%;
  }
}
.component_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-small);

  color: var(--text-color);
}

.component-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-big);
}

.admin-container {
  max-width: 1500px;
  margin: 0 auto;
  padding-bottom: var(--gap-big);
}
@media (max-width: 1500px) {
  .admin-container {
    padding: var(--gap-big);
    padding-top: 0;
  }
}

.container {
  display: grid;
  gap: var(--gap-big);
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  /* width: fill-available; */
  max-width: 1180px;
  margin: 0 auto;
  padding-bottom: var(--gap-big);
}

@media (max-width: 1500px) {
  .container {
    padding: var(--gap-big);
    padding-top: 0;
    padding-left: 0;
  }
}
@media (max-width: 992px) {
  .container {
    padding: 0 var(--padding-mobile) var(--padding-mobile) var(--padding-mobile);
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .container {
    padding: calc(var(--padding-mobile) / 2);
    padding-top: 0;
  }
}

.background-blue {
  background: rgb(11, 153, 255);
  background: linear-gradient(
      180deg,
      rgba(11, 153, 255, 1) 0%,
      rgba(126, 201, 255, 1) 35%,
      rgba(11, 153, 255, 1) 100%
    )
    no-repeat;
  width: 100%;
  height: 100dvh;
}

.App-content {
  display: grid;
  grid-template-columns: 240px auto;
  gap: 30px;
}

@media (max-width: 992px) {
  .App-content {
    gap: var(--padding-mobile);
  }
}
@media (max-width: 768px) {
  .App-content {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
