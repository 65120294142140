.functions-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-medium);
}

.functions-list__item {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: var(--gap-small);
}

.functions-list__item h4 {
    font-size: 20px;
    font-weight: 600;
}

.functions-list__item svg {
    max-width: 200px !important;
    max-height: 200px !important;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .functions-list {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (max-width: 500px) {
    .functions-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .functions-list__item svg {
        max-width: 150px;
        max-height: 150px;

    }
}