.nav-menu {
  display: none;
}

.nav-menu ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: baseline;
  justify-content: space-between;
  gap: calc(var(--gap-big) * 1.5);
  padding: 0 var(--padding-desktop);
  width: 100%;
}

.nav-menu-link {
  display: flex;
  width: 100%;
  justify-items: flex-start;
  flex-direction: column;
  align-items: center;
}

.nav-menu-link p {
  white-space: nowrap;
  font-size: 10px;
  text-align: center;
  color: var(--text-color);
}

.nav-menu-link svg{
  width: 27px;
  height: 27px;
}

/* .nav-menu-link:active svg{
  transform: scale(1.2) translateY(-3px);
}

.nav-menu-link:active p{
  transform: translateY(3px);
} */

/* Home */
#navHome path {
  fill: var(--grey-color-third) !important;
}

#navHome.active path {
  fill: var(--accent-color) !important;
}

/* Tasks */

#navTasks path{
  fill: white !important;
}
#navTasks rect {
  fill: var(--grey-color-third) !important;
}
#navTasks.active rect {
  fill: var(--accent-color) !important;
}

#navTasks.active path {
  fill: white !important;
}

/* Create Tasks */

/* #createTasks svg {
  width: 35px !important;
  height: 35px !important;
} */

#createTasks path {
  fill: white !important;
}

#createTasks circle {
  border: 10px solid white;
  fill: var(--grey-color-third) !important;
}

#createTasks.active circle {
  fill: var(--accent-color) !important;
}

#createTasks.active path {
  fill: white !important;
}

/* Account */

#account path {
  fill: var(--grey-color-third) !important;
}

#account.active path {
  fill: var(--accent-color) !important;
}

#account.active rect {
  fill: white !important;
}

@media (max-width: 768px) {
  .nav-menu {
    left: 50%;
    transform: translate(-50%);
    background: rgba(255, 255, 255, 0.9) !important;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    position: fixed;
    bottom: 10px;
    z-index: 5;
    gap: var(--gap-small);
    padding: calc(var(--padding-desktop) / 2);
    /* padding: calc(var(--padding-desktop) / 2) var(--padding-desktop)
      calc(var(--padding-desktop) * 1) var(--padding-desktop); */
    box-shadow: var(--box-shadow);
    /* border-radius: var(--border-radius-big) var(--border-radius-big) 0 0; */
    border-radius: var(--border-radius-medium);
    /* background-color: var(--background-component-color); */
  }
}
