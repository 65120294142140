.navigation {
  position: fixed;
  z-index: 5;
  height: 100%;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-self: start;
  justify-content: space-between;
  gap: var(--gap-small);
  padding: var(--padding-desktop);
  background-color: var(--background-component-color);
  box-shadow: var(--box-shadow);

  overflow-y: auto;
}
.navigation-empty {
  width: 200px;
}

.navigation-item {
  display: flex;
  flex-direction: column;
  gap: var(--gap-big);
}

.navigation-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation .logo {
  width: 60px;
  height: auto;
}

.navigation .logo-text {
  width: 135px;
  height: auto;
}

.navigation-balance {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: var(--border-radius-small);
  padding: var(--padding-mobile);
  background-color: var(--accent-color);
  color: white;
}
.navigation-balance__title {
  font-weight: 600;
}
.navigation-balance__sum {
  font-weight: 600;
  font-size: 20px;
}

.navigation nav ul {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  list-style: none;
}

.nav-link {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: var(--border-radius-small);
  color: black;
}

.nav-link.active {
  background-color: var(--inactive-button-color);
}

.copyright-info {
  color: var(--grey-color-second);
}

@media (max-width: 768px) {
  .navigation {
    display: none;
  }
  .navigation-empty {
    display: none;
  }
}
