.create-task {
  display: grid;
  gap: var(--gap-big);
}

.create-task__form {
  width: 100%;
}

.components-double {
  display: flex;
  gap: var(--gap-big);
}

@media (max-width: 992px) {
  .components-double {
    flex-direction: column;
  }
}

.promotion-cost {
  position: sticky;
  background: rgba(255, 255, 255, 0.9) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  bottom: var(--gap-big);
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: var(--gap-small);
  z-index: 3;
}

.promotion-cost button {
  justify-self: end;
}

@media (max-width: 768px) {
  .promotion-cost {
    bottom: calc(var(--gap-small) + 83px);
  }
}
@media (max-width: 591px) {
  .promotion-cost {
    grid-template-columns: 1fr;
  }
  .promotion-cost button {
    justify-self: center;
    width: 100%;
  }
}
