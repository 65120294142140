.landing {
  display: grid;
  gap: var(--gap-big);
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  /* width: fill-available; */
  max-width: 1280px;
  margin: 0 auto;
}

.landing-title {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-small);
  align-self: center;
}

.landing main {
  display: grid;
  gap: var(--gap-big);
}

.landing-header {
  display: grid;
  grid-template-areas:
    "landing-logo landing-button landing-icon"
    "landing-menu landing-menu landing-menu";
  grid-template-columns: 1fr auto;
}

.landing-header button {
  width: min-content;
  grid-area: landing-button;
}

.landing-header__logo {
  grid-area: landing-logo;
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.logo {
  width: 40px;
  height: auto;
}

.logo-text {
  width: 100px;
  height: auto;
}

.landing-header__nav {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.landing-header__nav nav ul {
  white-space: nowrap;
  display: flex;
  gap: var(--gap-small);
  list-style: none;
}

.landing-header__nav a {
  color: var(--text-color);
}

.landing h3 {
  color: var(--accent-color);
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.landing h2 {
  color: var(--text-color);
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
}

.header-burger {
  display: none;
  align-items: center;
  justify-content: center;
}

.header-burger__line {
  height: 3px;
  width: 30px;
  background-color: var(--accent-color);
  border-radius: 5px;
}

.header-burger__line:nth-child(even) {
  width: 25px;
}

@media (max-width: 1280px) {
  .landing {
    padding: 0 var(--padding-mobile);
  }

  .landing h2 {
    font-size: 32px;
  }

  .landing h3 {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .landing {
    padding: 0;
  }

  .landing h2 {
    font-size: 24px;
  }

  .landing-header__nav nav {
    display: none;
  }

  .header-burger {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.header {
  gap: 0 !important;
}

.header ul {
  display: flex;
  white-space: nowrap;
  list-style: none;
  overflow: hidden;
  flex-direction: column;
}

.header li a {
  color: var(--text-color);
  display: block;
  text-decoration: none;
}

.header ul li:first-child{
  padding-top: var(--padding-desktop);
}

/* menu */

.header .menu {
  grid-area: landing-menu;
  justify-self: auto;
  align-items: center;
  /* justify-content: center; */
  /* flex-direction: row; */
  gap: var(--gap-small);
  clear: both;
  max-height: 0;
  overflow-x: auto;
  width: 100%;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  grid-area: landing-icon;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  float: right;
  padding: var(--padding-desktop) var(--padding-mobile);
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  overflow-x: auto;
  max-height: 250px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .landing-header {
    display: grid;
    grid-template-areas: "landing-logo landing-menu landing-button";
    gap: var(--gap-small) !important;
  }
  .landing-header ul {
    flex-direction: row;
  }
  .landing-header ul li:first-child{
    padding-top: 0;
  }
  .landing-header li {
    float: left;
  }
  .landing-header li a {
    padding: 0;
  }
  .landing-header .menu {
    align-items: center;
    justify-self: end;
    clear: none;
    float: right;
    max-height: none;
    margin-top: 0;
  }
  .landing-header .menu-icon {
    display: none;
  }
  .landing-header li:last-child {
    padding-left: 25px;
  }
}
