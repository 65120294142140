.balance {
  width: 100%;
}

.balance-title {
  font-size: 24px;
  white-space: nowrap;
  text-align: left;
}

@media (max-width: 992px) {
  .balance-title {
    font-size: 20px;
  }
}

.balance-up {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-big);
}

.balance-bonus {
  display: flex;
  align-self: baseline;
  text-align: left;
  background-color: var(--accent-color);
  color: white;
  padding: 10px;
  border-radius: var(--border-radius-small);
  font-size: 20px;
}
@media (max-width: 992px) {
  .balance-bonus {
    font-size: 16px;
  }
}

.balance-left {
  display: grid;
  gap: var(--gap-small);
}
