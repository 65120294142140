.support {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: var(--gap-small);
}

.support-links {
  overflow: hidden;
  height: 0%;
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  transition: 0.5s ease-in-out;
}

.support-links .support-links__item {
  display: flex;
  gap: var(--gap-small);
  align-items: center;
  justify-content: left;
  opacity: .5;
  color: var(--text-color);
}

.support-links .support-links__item:hover {
  opacity: 1;
}

.support-links_active {
  height: 100%;
}

.support-title {
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
