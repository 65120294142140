.admin-panel {
  display: flex;
  flex-direction: column;
  gap: var(--gap-big);
}

.admin-panel header {
  display: flex;
  justify-content: space-between;
}

.admin-components {
  display: flex;
  flex-direction: column;
  gap: var(--gap-big);
}

.admin-components-list {
  display: grid;
  grid-template-columns: 1fr;
}

.admin-components-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-small);
}

.admin-panel .dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--gap-big);
}

.admin-panel .dashboard-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: var(--gap-small);
  color: var(--text-color);
}

.admin-table {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.admin-table__header {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-small);
}
.admin-table__header button {
  max-width: 250px;
}

.admin-table__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--gap-small);

  .admin-table__item {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--gap-small);

    .admin-table-item__content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: var(--gap-small);

      p {
        white-space: nowrap;
        background-color: var(--grey-color);
        padding: 5px;
        border-radius: var(--border-radius-small);
      }

      .admin-table-item__content_up {
        display: flex;
        gap: var(--gap-small);
      }

      .admin-table-item__content_down {
        display: flex;
        gap: var(--gap-small);
      }
    }

    .admin-table-item__buttons {
      display: flex;
      gap: var(--gap-small);
      justify-self: end;
    }
  }

  .admin-table__item:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: var(--border-grey);
  }
}
