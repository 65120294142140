.my-tasks {
  display: grid;
  gap: var(--gap-big);
}

.dashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  gap: var(--gap-big);
}

.my-tasks .dashboard-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-small);
  color: var(--text-color);

}

.my-tasks .dashboard-item__text {
  font-size: 20px;
}

.my-tasks .dashboard-item img {
  width: 100%;
}

.my-tasks .dashboard-item__create-task a {
  width: 100%;
}

.my-tasks .dashboard button {
  height: auto !important;
  width: 100% !important;
  padding: var(--padding-desktop);
}

@media (max-width: 1140px) {
  .dashboard {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .my-tasks .dashboard-item__create-task {
    grid-area: createTask !important;
  }
  .dashboard {
    grid-template-areas: "createTask createTask" ". . " !important;
  }
  .my-tasks .dashboard-item__xls {
    display: none !important;
  }
}
