.home-screen {
  display: grid;
  gap: var(--gap-big);
}

.home-screen .dashboard-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-small);
  color: var(--text-color);
}

.home-screen .dashboard-item__text {
  font-size: 20px;
}

.home-screen img {
  height: auto;
  width: 100%;
}

.home-screen .dashboard-up {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: var(--gap-big);
}

.home-screen .dashboard-item__create-task a {
  width: 100%;
}

.home-screen .dashboard-down {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  gap: var(--gap-big);
}
@media (max-width: 1440px) {
  .home-screen .dashboard-down {
    grid-template-columns: repeat(3, 1fr);
  }

  .home-screen .dashboard-up img {
    height: auto;
    width: auto;
  }
}
@media (max-width: 1170px) {
  .home-screen .dashboard-down {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 992px) {
  .home-screen .dashboard-item__adv {
    grid-area: adv;
  }
  .home-screen .dashboard-item__all-tasks {
    grid-area: all-tasks;
  }
  .home-screen .dashboard-item__active-tasks {
    grid-area: active-tasks;
  }
  .home-screen .dashboard-up {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "adv adv" "all-tasks active-tasks" !important;
  }
}
@media (max-width: 530px) {
  .home-screen .dashboard-up img {
    height: 100%;
    width: 100%;
  }

  .home-screen .dashboard-down {
    grid-template-columns: repeat(1, 1fr);
  }
}

.home-screen .dashboard button {
  width: 100% !important;
}
