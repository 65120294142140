header {
  top: 0;
  border-radius: 0 0 var(--border-radius-big) var(--border-radius-big) !important;
  padding: var(--padding-mobile) !important;
  position: sticky;
  align-items: center;
  z-index: 4;
  grid-template-columns: repeat(3, 1fr);
}

.header {
  background: rgba(255, 255, 255, 0.9) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.header-back {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-back svg {
  width: 15px;
  height: 15px;
}

.header-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.notification {
  justify-self: flex-start;
  position: relative;
  align-self: flex-end;
  justify-self: flex-end;
  cursor: pointer;
}
.notification svg {
  width: 25px;
  height: 25px;
}

.notification-quantity {
  position: absolute;
  display: flex;
  top: -10px;
  right: -10px;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1 / 1;

  color: white;
  width: 15px;
  height: 15px;
  background-color: var(--active-negative-color);
  border-radius: 100%;
  font-size: 10px;
  padding: 2px;
}

.notification-content {
  position: absolute;
  right: 0;
  top: calc(100% + var(--gap-big));
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  padding: var(--padding-desktop);
  max-height: 350px;
  max-width: 400px;
  white-space: nowrap;
  background-color: var(--background-component-color);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow);
}

.notification-content__null {
  padding: var(--padding-desktop) calc(var(--padding-desktop) * 2);
}

.notification-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  overflow-y: auto;
}
@media (max-width: 992px) {
  .notification-list {
    width: 100%;
  }
}

.notification-list__item {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  padding: var(--padding-mobile);
  border-radius: var(--border-radius-small);
  background-color: var(--background-page-color);
}

.notification-item__title {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
  justify-content: space-between;
}

.notification-item__title p {
  color: var(--grey-color-second);
}
