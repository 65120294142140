.modal-wrapper {
  overflow-y: auto;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.modal {
  position: relative;
  margin: 0 auto;
  top: var(--padding-desktop);
  /* max-width: 800px;
  width: max-content; */
  width: 700px;
  min-height: max-content;
  overflow: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-big);
}

.info-promotion {
  width: 50% !important;
  position: absolute;
  bottom: 110px;
  right: var(--padding-desktop);
  padding: var(--padding-mobile);
  background-color: white;
}

@media(max-width:700px) {
  .info-promotion {
    width: 90% !important;
    bottom: 137px;
  }
}

.info-promotion__button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-small);
}

.modal-range {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}
.modal-range__numbers {
  display: flex;
  justify-content: space-between;
}

.modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.improver-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "improver-header__img improver-header__text" "improver-header__img improver-header__text_small";
  gap: var(--gap-small);
}
.empty-services {
  height: 300px;
}
@media (max-width: 560px) {
  .improver-header {
    grid-template-areas: "improver-header__img improver-header__text" "improver-header__text_small improver-header__text_small";
  }
}

.improver-header__text {
  grid-area: improver-header__text;
  font-size: 24px;
  padding-right: 25px;
}

.improver-header__img {
  grid-area: improver-header__img;
  width: 90px;
  aspect-ratio: 1 / 1;
  border-radius: var(--border-radius-small);
  object-fit: cover;

}

.improver-header__text_small {
  grid-area: improver-header__text_small;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-small);
  color: var(--grey-color-second);
  white-space: nowrap;
}

.modal-header__img {
  width: 90px;
  aspect-ratio: 1 / 1;
}

.improver-services {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.improver-services__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--gap-small);
  max-width: 100%;
}

@media (max-width: 636px) {
  .improver-services__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 460px) {
  .improver-services__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.improver-services__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--padding-mobile);
  aspect-ratio: 1 / 1;
  max-width: 250px;
  /* width: max(40%, 80%); */
  border-radius: var(--border-radius-small);
  border: 1px solid transparent;
  background: linear-gradient(
    140deg,
    rgba(253, 176, 32, 0.3) 0%,
    rgba(11, 153, 255, 0.3) 100%
  );
  opacity: 0.5;
  cursor: pointer;
}

.improver-services__item_active {
  opacity: 1;
  border: 1px solid black;
  box-shadow: var(--box-shadow);
}

.improver-services__number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: max(40%, 80%);
  /* height: max(40%, 80%); */
  aspect-ratio: 1 / 1;
  color: white;
  font-size: 200%;
  font-weight: 600;
}

.improver-services__number_orange {
  background-color: #fdb020;
}
.improver-services__number_green {
  background-color: #60d669;
}
.improver-services__number_violet {
  background-color: #760bff;
}
.improver-services__number_red {
  background-color: #f34343;
}

.improver-services__price {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  gap: var(--gap-small);
}

.improver-services__price_crossed {
  text-decoration: line-through;
  font-size: 14px;
}

.payment {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.payment-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.payment-item {
  display: flex;
  gap: var(--gap-small);
  justify-content: space-between;
}

.payment-sum {
  display: grid;
  align-self: end;
}

.payment-text__second {
  color: var(--grey-color-second);
}

.payment-item__left {
  display: flex;
  gap: var(--gap-small);
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 690px) {
  .payment-item__left p {
    font-size: 12px;
  }
}

.payment-item__number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  width: 25px;
  color: white;
  font-size: 14px;
}
.payment-item__number_orange {
  background-color: #fdb020;
}
.payment-item__number_green {
  background-color: #60d669;
}
.payment-item__number_violet {
  background-color: #760bff;
}
.payment-item__number_red {
  background-color: #f34343;
}

.payment-row {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  gap: var(--gap-small);
}

.payment-row h3 {
  color: var(--grey-color-second);
}

@media (max-width: 768px) {
  .payment-row {
    flex-direction: column;
  }
  .payment-row button {
    width: 100%;
  }
}

.payment-sale {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}