.footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-medium);
  background-color: var(--accent-color) !important;
  color: var(--secont-text-color);
  border-radius: var(--border-radius-big) var(--border-radius-big) 0 0 !important;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-small);

  padding-bottom: var(--gap-medium);
  border-bottom: var(--border-white);
}

.footer-content a {
  color: white;
}

.footer-content ul {
  list-style: none;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-small);
  color: white;
}

.footer-info__politics {
  display: flex;
  gap: var(--gap-small);
}

.footer-info__politics a,
.footer-info__politics p {
  color: white;
  opacity: 0.5;
  transition: 0.1 ease-in-out opacity;
}

.footer-info__politics a:hover {
  opacity: 1;
  cursor: pointer;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.footer-logo__icon {
  width: 45px;
  height: 45px;
}

.footer-logo__text {
  width: 100px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.footer-links a svg path {
  fill: white;
}

.footer-links button:hover {
  background-color: white !important;
  color: black !important;
}

.footer-nav {
  display: flex;
  gap: calc(var(--gap-big) * 2);
}

.footer-nav h4 {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.footer-nav li {
  color: white;
  opacity: 0.5;
  transition: 0.1s ease-in-out opacity;
  cursor: pointer;
}

.footer-nav li:hover {
  opacity: 1;
}

.footer-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
}

@media (max-width: 630px) {
  .footer-links {
    width: 100%;
  }

  .footer-logo {
    justify-content: center;
  }

  .footer-nav {
    display: none;
  }

  .footer-info {
    flex-direction: column;
  }

  .footer-info__politics {
    flex-direction: column;
  }
  .footer-info__politics p {
    display: none;
  }
}
