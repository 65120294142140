.tabs {
  white-space: nowrap;
  display: flex;
  gap: var(--gap-small);
  margin-bottom: var(--gap-big);
}
.tabs-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: var(--background-component-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
  padding: 0 var(--padding-desktop);
  cursor: pointer;
}

.react-tabs__tab--selected {
  background-color: var(--accent-color);
  color: white;
  border: none;
  outline: none;
}
.react-tabs__tab:focus {
  outline: none;
}
