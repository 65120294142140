.table-products {
  display: grid;
  gap: var(--gap-small);
}
.table-products__header {
  display: flex;
  gap: var(--gap-small);
  justify-content: space-between;
}

.table-products__list {
  display: grid;
  gap: var(--gap-small);
}

.table-products__content {
  display: grid;
  gap: var(--gap-small);
}

.table-products_banned {
  position: relative;
}

.table-products_banned .table-products__list {
  padding: var(--padding-desktop);
}

.table-products__blur {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding: var(--padding-desktop);
  z-index: 2;
  overflow: hidden;
  border-radius: var(--border-radius-small);
}

@media (max-width: 992px) {
  .table-products__header {
    display: flex;
    flex-direction: column;
  }
  .input-search {
    width: 100% !important;
  }
}

.search {
  display: flex;
  align-items: center;
  position: relative;
}

.input-search {
  width: 300px;
  transition: 0.2s ease-in-out width;
}

.input-search:focus {
  width: 400px;
}

.button-search {
  position: absolute;
  right: 10px;
  width: auto;
  padding: 0;
  background-color: transparent;
}

.button-search svg {
  display: flex;
}

.table-item {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--gap-small);
  width: 100%;
}

.table-item__left {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.table-item__right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--gap-small);
}

@media(max-width:992px){
  .table-item__right {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.table-text__container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
}

.table-item__img {
  width: 75px;
  height: 75px;
  aspect-ratio: 1 / 1;
  border-radius: var(--border-radius-small);
  object-fit: cover;
}

.table-item__title {
  color: var(--accent-color);
  font-size: 20px;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
  white-space: normal;
}

.table-text__description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-small);
}

.table-item__id {
  color: var(--grey-color-second);
}

.table-item__location {
  color: var(--grey-color-second);
}

.table-item__text_price {
  white-space: nowrap;
  grid-area: price;
  color: var(--grey-color-second);
}

.table-item__service {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  aspect-ratio: 1 / 1;
  width: 30px;
  color: white;
  font-size: 14px;
  white-space: nowrap;
}
.table-item__service_orange {
  background-color: #fdb020;
}
.table-item__service_green {
  background-color: #60d669;
}
.table-item__service_violet {
  background-color: #760bff;
}
.table-item__service_red {
  background-color: #f34343;
}

.table-item__date,  .table-item__price{
  white-space: normal;
}

.table-item__buttons {
  display: flex;
  justify-content: end;
  gap: var(--gap-small);
}

.table-progressbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  max-width: 150px;
  height: 40px;
  padding: 0 var(--padding-desktop);
  border: 1px solid #36b14a60;
  border-radius: var(--border-radius-small);
  overflow: hidden;
}

@media(max-width:992px){
  .table-progressbar {
    max-width: 100%;
    width: 100%;
  }
}

.table-progressbar p {
  z-index: 1;
  white-space: nowrap;
}

.table-progressbar__strip {
  z-index: 0;
  position: absolute;
  width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #36b14a60;
}

@media (max-width: 992px) {
  .table-products__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .table-item {
    flex-direction: column;
    align-items: start;
  }

  .table-item__buttons {
    width: 100%;
  }

  .table-item__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }

  .table-item__img {
    width: 100%;
    height: 120px;
  }

  .table-item__left {
    align-items: start;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .table-text__description {
    align-items: normal;
    flex-direction: column;
  }

  .table-item .button-default {
    width: 100%;
  }

  .table-item .button-default svg {
    display: none;
  }
}

/* 
@media (max-width: 1439px) {
  .table-products__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .table-item {
    grid-template-columns: 1fr;
  }
  .table-item__left {
    position: relative;
    width: 100%;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "img title"
      "img price"
      "id location"
      "date date"
      "service-price service-price"
      "progressbar progressbar" !important;
  }
  .table-progressbar {
    max-width: 100% !important;
  }

  .table-item .button-default {
    width: 100%;
  }

  .table-item .button-default svg {
    display: none;
  }

  .table-item__service {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px !important;
    height: 20px;
    font-weight: 500;
    border-radius: 9px 9px 0 0 !important;
  }
  .table-item .button-default::after {
    content: "Редактировать";
  }
  .table-item__title {
    white-space: wrap;
    font-size: 16px !important;
  }
}

@media (max-width: 840px) {
  .table-products__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .table-products__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 580px) {
  .table-products__list {
    grid-template-columns: repeat(1, 1fr);
  }
} */
