.steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-medium);
}

.steps ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-small);
  list-style: none;
}

.steps li {
  display: flex;
  align-items: center;
  gap: var(--gap-small);
}

.steps-step {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--accent-color);
  color: var(--second-text-color);
}

.steps svg {
  width: auto;
  height: 100%;
}

.steps-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  /* background-color: rgb(236, 236, 236);   */
  border-radius: var(--border-radius-small);
}

.steps-right {
  display: flex;
  flex-direction: column;
  gap: var(--gap-small);
  justify-content: space-between;
}

.steps-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap-medium);
}

.steps button {
  width: max-content;
}

.steps a {
  display: flex;
  justify-content: start;
}

@media (max-width: 768px) {
  .steps {
    grid-template-columns: repeat(1, 1fr);
  }
  .steps button {
    width: max-content;
  }
  .steps a {
    display: flex;
    justify-content: center;
  }
}
